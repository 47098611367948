.categories {
    margin-inline: 0px;
}

p.MuiTypography-root.MuiTypography-body1.heading {
    font-weight: bold;
    font-size: 20px;
    margin-inline: 30px;
    margin-top: 30px;
}

p.MuiTypography-root.MuiTypography-body1.heading-category {
    font-weight: bold;
    font-size: 20px;
    margin-inline: 70px;
    margin-top: 0px;
}

p.MuiTypography-root.MuiTypography-body1.category-heading {
    font-weight: bold;
    font-size: 20px;
    position: relative;
    top: 40px;
    right: 20px;
    float: right;
    z-index: 2;
    /* color: #fff5d1; */
    color: white;

    /* font-weight: bolder;
    font-size: 20px;
    position: relative;
    top: 36px;  
    float: right;
    z-index: 2;
    color: black;
    padding: 3px;
    background: #ffd890;
    opacity: 0.8;
    border-radius: 0px 10px 0px 10px; */
}

.category-cards {
    /* background-color: black; */
    display: flex;
    flex-wrap: wrap;
    gap: 60px;
    padding: 0px;
    justify-content: center;

}

.category {
    height: 200px;
    width: 340px;
}