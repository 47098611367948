.footer {
    background-color: rgb(0 0 0 / 90%);
    /* flex-grow: 1; */
    /* display: flex;    */
    justify-content: space-around;
    margin-top: 80px;
    align-items: center;
    min-height: 25vh;
    padding: 30px
}

.content {
    display: flex;
    gap: 30px
}

p.MuiTypography-root.MuiTypography-body1.content-heading {
    color: white;
    margin-bottom: 10px;
}

p.MuiTypography-root.MuiTypography-body1.content-subheading {
    color: white;
    margin-bottom: 5px;
    font-size: 14px;
    opacity: 0.5;
}